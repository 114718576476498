export const gvirStatusConstants = {
    GET_REQUEST: 'GVIR_GET_REQUEST',
    GET_SUCCESS: 'GVIR_GET_SUCCESS',
    GET_FAILURE: 'GVIR_GET_FAILURE',
    GET_24_HOURS_REQUEST: 'GVIR_GET_24_HOURS_REQUEST',
    GET_24_HOURS_SUCCESS: 'GVIR_GET_24_HOURS_SUCCESS',
    GET_24_HOURS_FAILURE: 'GVIR_GET_24_HOURS_FAILURE',

    RELOAD_REQUEST: 'GVIR_RELOAD_REQUEST',
    RELOAD_SUCCESS: 'GVIR_RELOAD_SUCCESS',
    RELOAD_FAILURE: 'GVIR_RELOAD_FAILURE',
    RELOAD_24_HOURS_REQUEST: 'GVIR_RELOAD_24_HOURS_REQUEST',
    RELOAD_24_HOURS_SUCCESS: 'GVIR_RELOAD_24_HOURS_SUCCESS',
    RELOAD_24_HOURS_FAILURE: 'GVIR_RELOAD_24_HOURS_FAILURE',
};
