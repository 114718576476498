import React from 'react';
import Error404 from '../components/Error404';

const Error = () => {
    return (
        <div className='error'>
            <Error404 />
        </div>
    );
};

export default Error;
